import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/cPP4RC4Re_Y">
    <SEO title="Personal Growth - Growth" />
  </Layout>
)

export default SermonPost
